@import '../../Styles/main.scss';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $accent-color-extra-light;
  color: $neutral-dark;
  overflow: hidden;
  &__logo-container {
    display: flex;
    justify-content: flex-end;
    height: 5rem;
    width: 100%;
    background-color: $brand-xx-dark;
    overflow: hidden;
    cursor: pointer;
  }
  &__logo {
    position: absolute;
    height: 15rem;
    z-index: 2;
  }
  &__image {
    align-self: center;
    width: 100%;
  }
  &__gradient {
    background: linear-gradient(180deg, $brand-xx-dark, rgba(0, 0, 0, 0));
    height: 5rem;
    width: 100%;
  }
  &__content-container {
    overflow: hidden;
    padding: 1rem 1rem 0.5rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 1rem auto;
    padding: 0.75rem;
    padding-bottom: 0;
  }
  &__button-wrapper {
    position: absolute;
    display: flex;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
  &__button {
    @include button;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      @include buttonHover;
      transform: translate(0, -3px);
    }
  }
  &__new-window-icon {
    margin-left: 0.25rem;
    font-size: 1.125rem;
  }
  &__accordion {
    position: relative;
    top: -1px;
    > button {
      background-color: $brand-xx-dark;
      color: $white;
      box-shadow: #0f2d3e 0.25rem 0 0 inset;
      cursor: pointer;
    }
    &-nested-fwd-component-library {
      > button {
        background-color: $brand-x-dark;
      }
    }
    &-nested-spirit {
      > button {
        background-color: $spirit-dark;
      }
    }
    &-nested-our-planet {
      > button {
        background-color: $our-planet-dark;
      }
    }
    &-nested-palette-picker {
      > button {
        background-color: $palette-picker-dark;
      }
    }
    &-nested-swapi {
      > button {
        background-color: $swapi-dark;
      }
    }
    &-nested-medium {
      > button {
        background-color: $medium-dark;
      }
    }
  }
  &__accordion-content {
    background-color: $gray-02;
    display: flex;
    flex-direction: column;
    &-spirit {
      background-color: $spirit-main;
    }
    &-fwd-component-library {
      background-color: $brand-color;
    }
    &-our-planet {
      background-color: $our-planet-main;
    }
    &-palette-picker {
      background-color: $palette-picker-main;
    }
    &-swapi {
      background-color: $swapi-main;
    }
    &-medium {
      background-color: $medium-main;
    }
  }
  &__title {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 694px) {
  .card {
    &__logo-container {
      height: 2rem;
    }
    &__logo {
      height: 6rem;
    }
    &__button-wrapper {
      justify-content: center;
    }
  }
}
