//Layout, Box, Visual, Typography, Misc
@import '../../Styles/variables.scss';

.App {
  position: relative;
  height: 100vh;
  background-color: $brand-color;
  color: $snow;
  font-size: 1rem;
  overflow: hidden;
  transition: 0.5s background-color ease-in-out;
}

.away {
  background-color: $accent-color-extra-light;
  transition: 0.5s background-color ease-in-out;
  overflow-y: scroll;
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 694px) {
  .App {
    width: initial;
  }
}
