@import '../../Styles/main.scss';

.LandingPage {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include width(160);
  .wordmark {
    @include width(30);
    @include height(30);
    margin: 0 75px;
    background: {
      size: contain;
      repeat: no-repeat;
    }
  }
  .nav-bar {
    display: flex;
    align-self: center;
    justify-content: center;
    .nav-link-home {
      @include button;
      text-transform: capitalize;
      &:hover {
        @include buttonHover;
        transform: translate(0, -3px);
      }
    }
    .active {
      @include buttonHover;
      cursor: default;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 1634px) {
  .LandingPage {
    @include width(128);
  }
}

@media screen and (max-width: 1334px) {
  .LandingPage {
    @include width(96);
  }
}

@media screen and (max-width: 1014px) {
  .LandingPage {
    @include width(64);
  }
}

@media screen and (max-width: 694px) {
  .LandingPage {
    @include width(32);
  }
}
