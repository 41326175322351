@import '../../Styles/main.scss';

.nav-wrapper {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}
.header-wordmark {
  @include horizontalWordmarkLogo;
}
.nav-bar,
.nav-mobile,
.nav-desktop {
  align-self: center;
  display: flex;
  .nav-link {
    align-self: baseline;
    @include buttonAlternate;
    text-transform: capitalize;
    &:hover {
      @include buttonHoverAlternate;
    }
  }
  .active {
    @include buttonHoverAlternate;
    cursor: default;
    pointer-events: none;
  }
}
.nav-mobile {
  display: flex;
}
.nav-icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 200;
  &.close {
    width: 40px;
    height: 40px;
  }
}
.burger-line {
  height: 5px;
  width: 40px;
  margin: 3px;
  background-color: $brand-color;
  border-radius: 2px;
  transition: all 0.25s;
  &.close {
    background-color: $snow;
    position: absolute;
    top: 10px;
  }
  &.one {
    transform: rotate(135deg);
  }
  &.two {
    transform: rotate(-135deg);
  }
  &.remove {
    display: none;
  }
}
.nav-menu {
  display: flex;
  position: absolute;
  top: 0;
  bottom: -5px;
  background: $brand-dark;
  transition: all 0.5s;
  z-index: 100;
  &-hide {
    right: -480px;
    left: 480px;
  }
  &-show {
    right: 0;
    left: 0;
  }
}
.nav-bar-mobile {
  align-self: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  width: 100% !important;
  > .nav-link {
    color: $snow !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 694px) {
  .nav-wrapper {
    flex-direction: column;
    margin: 25px 0 50px;
  }
  .header-wordmark {
    @include height(5.4);
    width: unset;
    margin: 0;
    background-position: center;
  }
}

@media screen and (max-width: 480px) {
  .header-wordmark {
    @include verticalWordmarkLogo;
    align-self: center;
  }
  .nav-desktop {
    align-self: unset;
  }
  .nav-wrapper {
    width: 100%;
    min-height: 10rem;
    margin: 0;
  }
  .nav-bar-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    width: 100%;
  }
  .nav-link {
    box-sizing: border-box;
    width: 100% !important;
  }
}
