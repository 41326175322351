@import '../../Styles/main.scss';

.switch-wrapper {
  @include component-transition;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.routes-away {
  flex-direction: column;
}

.switch-wrapper.fade-enter {
  @include component-entering;
}

.switch-wrapper.fade-enter.fade-enter-active {
  @include component-enter-active;
}

.switch-wrapper.fade-exit {
  @include component-exiting;
}

.switch-wrapper.fade-exit.fade-exit-active {
  @include component-exit-active;
}