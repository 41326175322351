@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// Typography
$main-font: 'Open Sans', Arial, sans-serif;

// Colors
$brand-dark: rgb(77, 114, 152);
$accent-color-light: #319fd9;
$accent-color-extra-light: rgb(236, 246, 251);
$neutral-dark: rgb(60, 64, 66);
$neutral-light: rgb(125, 133, 137);
$snow: rgb(249, 249, 249);
$spirit-main: hsl(267, 45%, 38%);
$spirit-dark: hsl(267, 45%, 19%);
$medium-main: hsl(75, 39%, 41%);
$medium-dark: hsl(75, 39%, 22%);
$our-planet-main: hsl(158, 27%, 41%);
$our-planet-dark: hsl(158, 27%, 22%);
$palette-picker-main: hsl(55, 100%, 41%);
$palette-picker-dark: hsl(55, 100%, 22%);
$swapi-main: hsl(360, 78%, 41%);
$swapi-dark: hsl(360, 78%, 22%);

// Brand colors
$brand-white: hsl(201, 68%, 95%);
$brand-xx-light: hsl(200, 67%, 87%);
$brand-x-light: hsl(200, 68%, 78%);
$brand-light: hsl(201, 68%, 69%);
$brand-medium: hsl(200, 68%, 60%);
$brand-color: hsl(201, 69%, 52%);
$brand-hover: hsl(201, 64%, 47%);
$brand-active: hsl(200, 63%, 43%);
$brand-dark: hsl(201, 64%, 33%);
$brand-x-dark: hsl(200, 64%, 24%);
$brand-xx-dark: hsl(201, 62%, 15%);
$brand-black: hsl(200, 60%, 5%);
