@import '../../Styles/main.scss';

.Projects {
  display: flex;
  flex-direction: column;
  width: 1600px;
  max-height: 100vh;
  color: $neutral-dark;
  .card-container {
    @include margin(1);
    background-color: $brand-color;
  }
}

@media screen and (max-width: 1634px) {
  .Projects {
    width: 1280px;
  }
}

@media screen and (max-width: 1334px) {
  .Projects {
    width: 960px;
  }
}

@media screen and (max-width: 1014px) {
  .Projects {
    width: 640px;
  }
}

@media screen and (max-width: 694px) {
  .Projects {
    width: 320px;
    margin: 5px;
  }
}
