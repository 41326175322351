@import './variables.scss';

@mixin height($height) {
  height: convertRem($height);
}

// Boxsizing to REMs
@mixin maxHeight($maxHeight) {
  max-height: convertRem($maxHeight);
}

@mixin minHeight($minHeight) {
  min-height: convertRem($minHeight);
}

@mixin maxWidth($maxWidth) {
  max-width: convertRem($maxWidth);
}

@mixin minWidth($minWidth) {
  min-width: convertRem($minWidth);
}

@mixin width($width) {
  width: convertRem($width);
}

@mixin margin($margin) {
  margin: convertRem($margin);
}

@mixin padding($padding) {
  padding: convertRem($padding);
}

@mixin top($top) {
  top: convertRem($top);
}

@mixin right($right) {
  right: convertRem($right);
}

@mixin bottom($bottom) {
  bottom: convertRem($bottom);
}

@mixin left($left) {
  left: convertRem($left);
}

@function convertRem($size) {
  $remSize: $size * 0.625;
  @return #{$remSize}rem;
}

//Button Styling
@mixin button() {
  @include width(8);
  @include padding(1);
  border-bottom: 1px solid $snow;
  color: $snow;
  text-align: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
}

@mixin buttonAlternate() {
  @include width(8);
  @include padding(1);
  border-bottom: 1px solid $brand-color;
  color: $brand-color;
  text-align: center;
  text-decoration: none;
  transition: 0.25s ease-in-out;
}

@mixin buttonHover() {
  border-bottom: 1px solid $brand-color;
  background-color: $snow;
  color: $brand-color;
  transition: 0.25s ease-in-out;
}

@mixin buttonHoverAlternate() {
  border-bottom: 1px solid $brand-color;
  background-color: $brand-color;
  color: $snow;
  transition: 0.25s ease-in-out;
}

@mixin activeButton() {
  cursor: default;
}

// Content Page Styling
@mixin contentHeader() {
}

@mixin contentImage() {
}

@mixin contentDetailTitle() {
}

@mixin contentDetailBody() {
}

// Image Styling
@mixin parallaxImage() {
  background: {
    attachment: fixed;
    position: center;
    repeat: no-repeat;
    size: cover;
  }
}

@mixin largeWordmarkLogo() {
}

@mixin horizontalWordmarkLogo() {
  @include height(5);
  @include width(35.7);
  @include margin(1);
  background: {
    size: contain;
    repeat: no-repeat;
  }
}

@mixin verticalWordmarkLogo() {
  height: 10rem;
  width: 10rem;
  background: {
    size: contain;
    repeat: no-repeat;
  }
}

@mixin icon() {
  @include width(3);
  @include height(3);
  background: {
    repeat: no-repeat;
    size: contain;
  }
}

// Transitions
@mixin component-entering {
  opacity: 0;
  transform: translate(0, -100%);
}

@mixin component-enter-active {
  opacity: 1;
  transform: translate(0, 50%);
}

@mixin component-exiting {
  opacity: 1;
  transform: translate(0, -50%);
}

@mixin component-exit-active {
  opacity: 0;
  transform: translate(0, 500%);
}

@mixin component-transition {
  transition: 1s cubic-bezier(0.78, 0.07, 0.35, 1);
}

// Animations
@mixin baseLoader() {
  display: inline-block;
  position: relative;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin buttonLoader() {
  @include baseLoader();
  width: 2.6rem;
  height: 2.6rem;
  div {
    width: 2.6rem;
    height: 2.6rem;
    border: 3px solid $snow;
    border-color: $snow transparent transparent transparent;
  }
}

@mixin largeLoader() {
  @include baseLoader();
  width: 7.5rem;
  height: 7.5rem;
  div {
    width: 6.2rem;
    height: 6.2rem;
    margin: 0.6rem;
    border: 8px solid $brand-color;
    border-color: $brand-color transparent transparent transparent;
  }
}
